import { LinkBuilder } from '../link-builder';
import {
  GetDocumentPreviewByIdResult,
  GetRemoteSigningSessionByIdResult,
  GetRemoteSigningSessionInfoFromOtherIdsResult,
  PostRemoteSigningSessionByIdParams, PostRemoteSigningSessionByIdResult,
  PutRemoteSigningSessionDeclineReasonRequestBody
} from '@property-folders/contract';
import { GetSigningSessionHistoryResult } from '@property-folders/contract/rest/property';

export class NotFoundError extends Error {}

export async function getRemoteSessionId(remoteSessionId: string | undefined): Promise<GetRemoteSigningSessionByIdResult> {
  const result = await fetch(LinkBuilder.restApi(`/remote-signing-sessions/${remoteSessionId}`));

  if (!result.ok) {
    if (result.status === 404) throw new NotFoundError();
    throw new Error('Unexpected');
  }

  return await result.json() as GetRemoteSigningSessionByIdResult;
}

export class DocumentVoidedError extends Error {
  constructor() {
    super('This document has been voided.');
  }
}

export async function postRemoteSessionId(remoteSessionId: string, body: PostRemoteSigningSessionByIdParams): Promise<{ allSigned: boolean, returnUrl?: string }> {
  // make a copy so we can safely remove values
  const copy = JSON.parse(JSON.stringify(body)) as PostRemoteSigningSessionByIdParams;
  // no point transmitting image data if it's not going to replace what's stored.
  if (!copy.party.images.initials?.replace) {
    delete copy.party.images.initials;
  }
  if (!copy.party.images.signature?.replace) {
    delete copy.party.images.signature;
  }
  const response = await fetch(LinkBuilder.restApi(`/remote-signing-sessions/${remoteSessionId}`), {
    method: 'POST',
    body: JSON.stringify(copy)
  });

  if (!response.ok) {
    if (response.status === 410) {
      throw new DocumentVoidedError();
    }
    throw new Error('There was a problem completing signing');
  }

  const result = (await response.json() as PostRemoteSigningSessionByIdResult);
  return {
    allSigned: !!(result.status === 'completed' && result.allSigned),
    returnUrl: result.returnUrl
  };
}

export async function postRegenerateRemoteSessionLink(remoteSessionId: string): Promise<{success: boolean, errorMessage?: string}> {
  try {
    await fetch(LinkBuilder.restApi(`/remote-signing-sessions/${remoteSessionId}/resend-requests`), {
      method: 'POST'
    });

    return {
      success: true
    };
  } catch (err: unknown) {
    console.error(err);
    return {
      success: false,
      errorMessage: 'An unexpected error occurred. Please try again.'
    };
  }
}

export async function getRemoteSessionIdFromSessionParty(signingSessionId: string | undefined, partyId: string | undefined, maxAttempts = 100): Promise<string> {
  let attemptNumber = 1;
  while (attemptNumber <= maxAttempts) {
    const response = await fetch(LinkBuilder.restApi(`/signing-sessions/${signingSessionId}/parties/${partyId}/remote-sessions/latest`));

    if (response.ok) {
      return (await response.json() as GetRemoteSigningSessionInfoFromOtherIdsResult).remoteSessionId;
    }

    console.log(`[${attemptNumber}] no result, sleeping...`);
    await new Promise<void>(resolve => setTimeout(resolve, 2000));
    attemptNumber += 1;
  }

  throw new Error('Could not load it');
}

export async function getDocumentPreviewById(documentPreviewId: string | undefined) {
  const result = await fetch(LinkBuilder.restApi(`/document-previews/${documentPreviewId}`));
  return await result.json() as GetDocumentPreviewByIdResult;
}

export async function putDeclineReason(remoteSessionId: string, body: PutRemoteSigningSessionDeclineReasonRequestBody) {
  try {
    const result = await fetch(LinkBuilder.restApi(`/remote-signing-sessions/${remoteSessionId}/decline-reason`), {
      method: 'PUT',
      body: JSON.stringify(body)
    });

    if (!result.ok) {
      return {
        success: false,
        errorMessage: 'An unexpected error occurred. Please try again.'
      };
    }

    return {
      success: true
    };
  } catch (err: unknown) {
    console.error(err);
    return {
      success: false,
      errorMessage: 'An unexpected error occurred. Please try again.'
    };
  }
}

export async function postVoid(remoteSessionId: string) {
  try {
    const result = await fetch(LinkBuilder.restApi(`/remote-signing-sessions/${remoteSessionId}/void`), {
      method: 'POST'
    });

    if (!result.ok) {
      return {
        success: false,
        errorMessage: 'An unexpected error occurred. Please try again.'
      };
    }

    return {
      success: true,
      returnUrl: (await result.json() as { returnUrl?: string })?.returnUrl
    };
  } catch (err: unknown) {
    console.error(err);
    return {
      success: false,
      errorMessage: 'An unexpected error occurred. Please try again.'
    };
  }
}

export async function putTAndCAgree(remoteSessionId: string) {
  try {
    await fetch(LinkBuilder.restApi(`/remote-signing-sessions/${remoteSessionId}/tandc-agree`), {
      method: 'PUT'
    });

  } catch (err: unknown) {
    console.error('Couldn\'t declare access', err);
  }
}

export function getSigningSessionHistory(url: string) {
  const abortController = new AbortController();
  return {
    abort: () => {
      abortController.abort();
    },
    response: fetch(
      url,
      { signal: abortController.signal }
    ).then(async result => {
      return await result.json() as GetSigningSessionHistoryResult;
    })
  };
}

export async function createPropertyFromEnvelope(envelopeId: string) {
  try {
    const result = await fetch(LinkBuilder.restApi('/properties/from-envelope'), {
      method: 'POST',
      body: JSON.stringify({ envelopeId })
    });
    const json = await result.json();
    return { success: true, propertyFolderId: json?.propertyFolderId, agencyName: json?.sourceEntityName };
  }
  catch (err: unknown) {
    console.error(err);
    return {
      success: false,
      errorMessage: err
    };
  }
}

export async function updateEnvelopeClaimMetadata(envelopeId: string, metadata: {[key: string]: any}) {
  try {
    await fetch(LinkBuilder.restApi(`/envelopes/${envelopeId}/metadata`), {
      method: 'PUT',
      body: JSON.stringify(metadata)
    });
    return { success: true };
  }
  catch (err: unknown) {
    console.error('Failed to update envelope metadata', err);
    return {
      success: false,
      errorMessage: err
    };
  }
}

export async function getEnvelopeClaimMetadata(envelopeId: string) {
  try {
    const result = await fetch(LinkBuilder.restApi(`/envelopes/${envelopeId}/metadata`));
    const metadata = await result.json();
    return { success: true, metadata };
  }
  catch (err: unknown) {
    console.error('Failed to get envelope metadata', err);
    return {
      success: false,
      errorMessage: err
    };
  }
}

export async function createEnvelopeFromProperty(propertyId: string, entityUuid: string) {
  try {
    const result = await fetch(LinkBuilder.restApi(`/properties/${propertyId}/envelope`), {
      method: 'POST',
      body: JSON.stringify({ destinationEntityUuid: entityUuid })
    });
    return { success: true, envelopeId: (await result.json())?.envelopeId };
  }
  catch (err: unknown) {
    console.error(err);
    return {
      success: false,
      errorMessage: err
    };
  }
}

export async function releaseSubscriptionForm(propertyId: string, formCode: string, formId: string, pdfUrl: string | undefined, customMessage?: string, sendSurvey?: boolean) {
  try {
    await fetch(LinkBuilder.restApi(`/properties/${propertyId}/release`), {
      method: 'POST',
      body: JSON.stringify({
        formCode,
        formId,
        pdfUrl,
        customMessage,
        sendSurvey
      })
    });
    return { success: true };
  }
  catch (err: unknown) {
    console.error(err);
    return {
      success: false,
      errorMessage: err
    };
  }
}
