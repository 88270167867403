import { SigningPartySourceType } from '@property-folders/contract';

export function mapPartyType(t: string): SigningPartySourceType {
  t = t.toLowerCase();

  switch (t) {
    case 'vendor':
      return SigningPartySourceType.Vendor;

    case 'vendor1':
      return SigningPartySourceType.VendorFirstParty;

    case 'vendor2':
      return SigningPartySourceType.VendorSecondParty;

    case 'purchaser':
      return SigningPartySourceType.Purchaser;

    case 'purchaser1':
      return SigningPartySourceType.PurchaserFirstParty;

    case 'purchaser2':
      return SigningPartySourceType.PurchaserSecondParty;

    case 'agent':
      return SigningPartySourceType.Salesperson;
  }

  if (t.startsWith('vendor')) {
    return SigningPartySourceType.Vendor;
  } else if (t.startsWith('purchaser')) {
    return SigningPartySourceType.Purchaser;
  } else if (t.startsWith('agent')) {
    return SigningPartySourceType.Salesperson;
  }

  return SigningPartySourceType.Error;
}
